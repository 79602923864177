import top from "./components/top";
import smartClothingA from "./components/smart-clothing-a";
import smartClothingB from "./components/smart-clothing-b";
import smartClothingC from "./components/smart-clothing-c";
import smartClothingD from "./components/smart-clothing-d";
import smartClothingE from "./components/smart-clothing-e"; // import commonFooter1 from "@/components/common-footer1";

export default {
  metaInfo: {
    title: "智能服装门店解决方案",
    // set a title
    meta: [{
      // set meta
      name: "keyWords",
      content: "RFID智能服装门店,服装RFID盘点,服装RFID解决方案,RFID服装管理系统,常达,服装erp软件,服装零售erp,快时尚ERP,智慧门店,服装软件,服装RFID收银系统,服装RFID管理系统,服装erp,服装erp管理系统,服装rfid,服装门店管理系统,服装erp系统,rfid吊牌,rfid服装,rfid服装吊牌,rfid服装吊牌厂家,服装rfid吊牌"
    }, {
      name: "description",
      content: "常达智能物联运用RFID技术引入商品管理，结合无线网络通讯技术的应用，实现销售现场商品信息的实时查询，以及销售数据的上传、智能化统计与管理，方便快捷，提升工作效率，降低运营成本的同时，显著提高商场档次，提升消费者购物体验。"
    }]
  },
  components: {
    top: top,
    smartClothingA: smartClothingA,
    smartClothingB: smartClothingB,
    smartClothingC: smartClothingC,
    smartClothingD: smartClothingD,
    smartClothingE: smartClothingE // commonFooter1,

  },
  data: function data() {
    return {
      swiperTitle: "应用详情",
      swiperList: [{
        t: "库存盘点",
        c: "利用RFID读取器实现库存货品的快速盘点及高效交接，品类数目一目了然，实现库存的可视化管理，极大提升作业效率及供应链的反馈速度。",
        icon: require("@/assets/smart-clothing/1.png"),
        icon2: require("@/assets/smart-clothing/1c.png")
      }, {
        t: "智能导购",
        c: "利用RFID读取器自动识别标签信息，连接数据库系统，实时获取该商品相应的所有图片及文字信息，第一时间响应客户需求。",
        icon: require("@/assets/smart-clothing/2.png"),
        icon2: require("@/assets/smart-clothing/2c.png")
      }, {
        t: "智能销售",
        c: "读取器可自动统计商品的销售情况，如商品种类、规格、数量、销售额等，并通过网络实时上传数据库系统，方便管理者实时获取销售信息，及时进行商品配置，制定经营策略，管理更高效。",
        icon: require("@/assets/smart-clothing/3.png"),
        icon2: require("@/assets/smart-clothing/3c.png")
      }, {
        t: "安全管理",
        c: "RFID应用主要集中在商品防盗，每个RFID标签拥有全球唯一的ID号码，不需要再对每一件货品的条码标签进行手动识读，能够在不添加额外劳动力成本的基础上，提高效率并提高透明度。",
        icon: require("@/assets/smart-clothing/4.png"),
        icon2: require("@/assets/smart-clothing/4c.png")
      }],
      swiperTitle1: "让零售运营更高效 让门店经营更智慧",
      swiperList1: [{
        t: "对于品牌商",
        c: "以数据为驱动，挖掘企业自身数据价值， 实现品牌的全数字化经营，驱动品牌智能发展",
        img: require("@/assets/smart-clothing/5.png")
      }, {
        t: "对于门店",
        c: "门店数据全面解析，智能指导门店经营，提高门店坪效",
        img: require("@/assets/smart-clothing/5.png")
      }, {
        t: "对于消费者",
        c: "服务统一，体验升级，让品牌客户更加便捷、舒适地购物，打造品牌忠诚度",
        img: require("@/assets/smart-clothing/5.png")
      }],
      recommend: {
        title: "智能服装产品推荐",
        list: [{
          image: require("@/assets/smart-clothing/recommend/1.png"),
          to: "/product?name=RFID服装标签&current=1"
        }, {
          image: require("@/assets/smart-clothing/recommend/2.png"),
          to: "/product?name=手持机"
        }, {
          image: require("@/assets/smart-clothing/recommend/3.png"),
          to: "/product?name=试衣间设备"
        }, {
          image: require("@/assets/smart-clothing/recommend/4.png"),
          to: "/product?name=通道门"
        }, {
          image: require("@/assets/smart-clothing/recommend/5.png"),
          to: "/product?name=智能收银机"
        }]
      },
      swiper2list: [{
        image: require("@/assets/smart-clothing/b/1.jpg"),
        text: "RFID智慧门店</br>系统的应用场景",
        imgOffset: "-40%",
        icon: require("@/assets/smart-clothing/swiper2/icon1.png"),
        icon1: require("@/assets/smart-clothing/swiper2/icon1c.png")
      }, {
        image: require("@/assets/smart-clothing/b/2.png"),
        text: "智能魔镜",
        imgOffset: "-53%",
        icon: require("@/assets/smart-clothing/swiper2/icon2.png"),
        icon1: require("@/assets/smart-clothing/swiper2/icon2c.png")
      }, {
        image: require("@/assets/smart-clothing/b/3.jpg"),
        text: "智能货架",
        imgOffset: "-10%",
        icon: require("@/assets/smart-clothing/swiper2/icon3.png"),
        icon1: require("@/assets/smart-clothing/swiper2/icon3c.png")
      }, {
        image: require("@/assets/smart-clothing/b/4.jpg"),
        text: "智能试衣间",
        imgOffset: "-24%",
        icon: require("@/assets/smart-clothing/swiper2/icon4.png"),
        icon1: require("@/assets/smart-clothing/swiper2/icon4c.png")
      }, {
        image: require("@/assets/smart-clothing/b/5.jpg"),
        text: "货物盘点功能",
        imgOffset: "-60%",
        icon: require("@/assets/smart-clothing/swiper2/icon5.png"),
        icon1: require("@/assets/smart-clothing/swiper2/icon5c.png")
      }, {
        image: require("@/assets/smart-clothing/b/6.png"),
        text: "RFID智慧收银",
        imgOffset: "-80%",
        icon: require("@/assets/smart-clothing/swiper2/icon6.png"),
        icon1: require("@/assets/smart-clothing/swiper2/icon6c.png")
      }, {
        image: require("@/assets/smart-clothing/b/7.png"),
        text: "防盗防损系统",
        imgOffset: "-44%",
        icon: require("@/assets/smart-clothing/swiper2/icon7.png"),
        icon1: require("@/assets/smart-clothing/swiper2/icon7c.png")
      }]
    };
  },
  mounted: function mounted() {
    new Wow.WOW({
      animateClass: "animate__animated"
    }).init();
  }
};