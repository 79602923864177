export default {
  data: function data() {
    return {
      current: 0,
      nav: [{
        title: "登录系统",
        list: [{
          t: "登录方式",
          image: require("@/assets/smart-clothing/a/login/1.png")
        }, {
          t: "首页功能展示",
          image: require("@/assets/smart-clothing/a/login/2.png")
        }]
      }, {
        title: "基础管理",
        list: [{
          t: "门店管理",
          image: require("@/assets/smart-clothing/a/basis/1.png")
        }, {
          t: "角色管理",
          image: require("@/assets/smart-clothing/a/basis/2.png")
        }, {
          t: "供应商管理",
          image: require("@/assets/smart-clothing/a/basis/3.png")
        }]
      }, {
        title: "产品管理",
        list: [{
          t: "产品管理",
          image: require("@/assets/smart-clothing/a/product/1.png")
        }, {
          t: "产品标签",
          image: require("@/assets/smart-clothing/a/product/2.png")
        }]
      }, {
        title: "单据管理",
        list: [{
          t: "仓库配送单",
          image: require("@/assets/smart-clothing/a/receipt/1.png")
        }, {
          t: "门店调入单",
          image: require("@/assets/smart-clothing/a/receipt/2.png")
        }, {
          t: "门店销售单",
          image: require("@/assets/smart-clothing/a/receipt/3.png")
        }]
      }, {
        title: "销售相关",
        list: [{
          t: "会员管理",
          image: require("@/assets/smart-clothing/a/sell/1.png")
        }, {
          t: "产品衣架使用情况",
          image: require("@/assets/smart-clothing/a/sell/2.png")
        }, {
          t: "门店流量",
          image: require("@/assets/smart-clothing/a/sell/3.png")
        }]
      }, {
        title: "库存相关",
        list: [{
          t: "盘点信息",
          image: require("@/assets/smart-clothing/a/stock/1.png")
        }, {
          t: "产品移位",
          image: require("@/assets/smart-clothing/a/stock/2.png")
        }, {
          t: "产品库存预警",
          image: require("@/assets/smart-clothing/a/stock/3.png")
        }]
      }]
    };
  },
  computed: {
    list: function list() {
      var current = this.current,
          nav = this.nav;
      return nav[current].list || [];
    }
  }
};