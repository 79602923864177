export default {
  data: function data() {
    return {
      list: [{
        icon: require("@/assets/smart-clothing/e/icon1.png"),
        t: "库存盘点",
        c: "利用RFID读取器实现库存货品的快速盘点及高效交接，品类数目一目了然，实现库存的可视化管理，极大提升作业效率及供应链的反馈速度。"
      }, {
        icon: require("@/assets/smart-clothing/e/icon2.png"),
        t: "智能导购",
        c: "利用RFID读取器自动识别标签信息，连接数据库系统，实时获取该商品相应的所有图片及文字信息，第一时间响应客户需求。"
      }, {
        icon: require("@/assets/smart-clothing/e/icon3.png"),
        t: "销售管理",
        c: "读取器可自动统计商品的销售情况，如商品种类、规格、数量、销售额等，并通过网络实时上传数据库系统，方便管理者实时获取销售信息，及时进行商品配置，制定经营策略，管理更高效。"
      }, {
        icon: require("@/assets/smart-clothing/e/icon4.png"),
        t: "安全管理",
        c: "RFID应用主要集中在商品防盗，每个RFID标签拥有全球唯一的ID号码，不需要再对每一件货品的条码标签进行手动识读，能够在不添加额外劳动力成本的基础上，提高效率并提高透明度。"
      }]
    };
  }
};