export default {
  mounted: function mounted() {
    var domRight = this.$refs.right;
    $(window).on("scroll", function () {
      var scrollTop = document.documentElement.scrollTop | document.body.scrollTop;
      var y;

      if (scrollTop > 200) {
        y = 200;
      } else {
        y = scrollTop;
      }

      $(domRight).css({
        top: "".concat((y + 100) / 100, "rem")
      }); // let domBoxTop = $(domImage).offset().top;
      // let y = scrollTop - domBoxTop;
      // if (y > 0 && y < 200) {
      //   $(domImage).css({
      //     transform: `translateY(${y / 1.5}px)`,
      //   });
      // }
    });
  },
  unmounted: function unmounted() {
    $(window).unbind();
  }
};