export default {
  data: function data() {
    return {
      list: [{
        t: "对于品牌商",
        c: "以数据为驱动，挖掘企业自身数据价值，实现品牌的全数字化经营，驱动品牌智能发展",
        icon: require("@/assets/smart-clothing/d/icon1.png")
      }, {
        t: "对于门店",
        c: "门店数据全面解析，智能指导门店经营，提高门店坪效",
        icon: require("@/assets/smart-clothing/d/icon2.png")
      }, {
        t: "对于消费者",
        c: "服务统一，体验升级，让品牌客户更加便捷、舒适地购物，打造品牌忠诚度",
        icon: require("@/assets/smart-clothing/d/icon3.png")
      }]
    };
  }
};